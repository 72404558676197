.rc-draggable-list-draggableRow {
  cursor: move;
}
.rc-draggable-list-handles {
  margin-right: 10px;
  font: bold 20px Sans-Serif;
  color: #5F9EDF;
  display: inline-block;
}
.rc-draggable-list-ghost {
  opacity: .2;
  background-color: rgba(0, 0, 0, 0.1);
}
